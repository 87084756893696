@import '~@gisatcz/ptr-core/lib/styles/_variables';
@import '~@gisatcz/ptr-maps';
@import '~@gisatcz/ptr-components';
@import '~@gisatcz/ptr-atoms';
@import '~@gisatcz/ptr-core';
@import '~@gisatcz/ptr-charts';
@import 'variables';

.ptr-light {
	--accent0: #{$lightAccent0};
	--accent0rgb: #{$lightAccent0rgb};
	--accent05: #{$lightAccent05};
	--accent10: #{$lightAccent10};
	--accent15: #{$lightAccent15};
	--accent20: #{$lightAccent20};
	--accent25: #{$lightAccent25};
	--accent30: #{$lightAccent30};
	--accent35: #{$lightAccent35};
	--accent40: #{$lightAccent40};
	--accent45: #{$lightAccent45};
	--accent50: #{$lightAccent50};
	--accent50rgb: #{$lightAccent50rgb};
	--accent55: #{$lightAccent55};
	--accent60: #{$lightAccent60};
	--accent65: #{$lightAccent65};
	--accent70: #{$lightAccent70};
	--accent75: #{$lightAccent75};
	--accent80: #{$lightAccent80};
	--accent85: #{$lightAccent85};
	--accent90: #{$lightAccent90};
	--accent95: #{$lightAccent95};
	--accent100: #{$lightAccent100};
	--accent100rgb: #{$lightAccent100rgb};
}

.ptrTest-side-panel {
	padding: m(1);
}

.flex-column-box {
	display: flex;
	flex-direction: column;
	margin-right: m(1);
	margin-bottom: m(1);
	flex: 1;
}
